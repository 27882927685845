
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.showcase {
  padding: 10px;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 5px;
    border: 1px solid black;

    .name {
      margin-top: 10px;
    }
  }
}
